// @ts-nocheck
/* eslint-enable */
/* eslint-disable camelcase */
// Lodash
import assign from 'lodash/assign';
import forEach from 'lodash/forEach';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';

// Utils
import { filterUtils_dedupeFilterValues, filterUtils_presets } from 'app/shared/utils/filterUtils';
import genericUtils from 'app/shared/utils/genericUtils';

const { isLooseTrue, createRangeArray } = genericUtils;

const controller = {
  queryObjToFilterObj(queryObj = {}) {
    const filterObj = {};
    const delimiter = filterUtils_presets.delimiter;
    const legend = filterUtils_presets.legend;

    forEach(queryObj, (filterValue, filterKey) => {
      if (legend[filterKey]) {
        filterObj[legend[filterKey]] = filterValue;
      } else if (filterValue && filterKey === 'price') {
        const priceArray = filterUtils_dedupeFilterValues(filterValue).split(delimiter);

        if (priceArray.length > 1) {
          filterObj.lowPrice = priceArray[0];
          filterObj.highPrice = priceArray[1];
        } else {
          filterObj.lowPrice = priceArray[0];
        }
      } else if (filterValue && filterKey === 'beds') {
        const bedroomsArray = filterUtils_dedupeFilterValues(filterValue).split(delimiter);
        const specialAppend = bedroomsArray[1] === '8plus' ? '8plus' : false;

        if (bedroomsArray.length > 2) {
          filterObj.bedrooms = bedroomsArray;
        } else if (bedroomsArray.length > 1) {
          filterObj.bedrooms = createRangeArray(1, bedroomsArray[0], bedroomsArray[1], specialAppend);
        } else {
          filterObj.bedrooms = bedroomsArray;
        }
      } else if (filterValue && filterKey === 'baths') {
        const bathroomsArray = filterUtils_dedupeFilterValues(filterValue).split(delimiter);
        const specialAppend = bathroomsArray[1] === '8plus' ? '8plus' : false;

        if (bathroomsArray.length > 2) {
          filterObj.bathrooms = bathroomsArray;
        } else if (bathroomsArray.length > 1) {
          filterObj.bathrooms = createRangeArray(0.5, bathroomsArray[0], bathroomsArray[1], specialAppend);
        } else {
          filterObj.bathrooms = bathroomsArray;
        }
      } else if (filterValue && filterKey === 'pets') {
        // 	`searchSlugToFilter` api returns `any` to accomodate resetting pet filter
        if (!includes(filterValue, 'any')) {
          filterObj.pets = filterUtils_dedupeFilterValues(filterValue).split(delimiter).join(',');
        }
      } else if (filterValue && filterKey === 'sqft') {
        const sqftArray = filterUtils_dedupeFilterValues(filterValue).split(delimiter);

        if (sqftArray.length > 1) {
          filterObj.minSqft = sqftArray[0];
          filterObj.maxSqft = sqftArray[1];
        } else {
          filterObj.minSqft = sqftArray[0];
        }
      } else if (filterValue && (filterKey === 'propertyTypes' || filterKey === 'properties')) {
        filterObj.propertyTypes = filterUtils_dedupeFilterValues(filterValue).split(delimiter).join(',');
      } else if (filterValue && (filterKey === 'listingTypes' || filterKey === 'listings')) {
        filterObj.listingTypes = filterUtils_dedupeFilterValues(filterValue).split(delimiter).join(',');
      } else if (filterKey === 'includeVaguePricing') {
        filterObj.includeVaguePricing = isLooseTrue(filterValue);
      } else if (filterKey === 'currentPage') {
        filterObj.currentPage = filterValue;
      } else if (filterKey === 'feeds') {
        filterObj.feeds = filterValue;
      } else if (filterKey === 'militaryHousing') {
        filterObj.militaryHousing = isLooseTrue(filterValue);
      } else if (filterKey === 'studentHousing') {
        filterObj.studentHousing = isLooseTrue(filterValue);
      } else if (filterKey === 'seniorHousing') {
        filterObj.seniorHousing = isLooseTrue(filterValue);
      } else if (filterKey === 'incomeRestricted') {
        filterObj.incomeRestricted = isLooseTrue(filterValue);
      } else if (filterKey === 'page') {
        filterObj.page = filterValue;
      } else if (filterValue && filterKey === 'amenities') {
        const allTriggeredAmenities = filterValue.split(delimiter);
        const formattedAmenities = allTriggeredAmenities.reduce((amenitiesObj, amenity) => {
          amenitiesObj[amenity] = true;
          return amenitiesObj;
        }, {});

        filterObj.amenities = formattedAmenities;
      } else if (filterValue && filterKey === 'laundry') {
        // 	`searchSlugToFilter` api returns `any` to accomodate resetting laundry filter
        if (!includes(filterValue, 'any')) {
          filterObj.laundry = filterUtils_dedupeFilterValues(filterValue).split(delimiter).join(',');
        }
      } else if (filterKey === 'promo') {
        filterObj.hasSpecialOffers = filterValue;
      } else if (filterKey === 'applicationsOk') {
        filterObj.isAcceptingRentalApplications = filterValue;
      } else if (filterKey === 'avail') {
        const [from, to] = filterValue.split('to');

        filterObj.startOfAvailabilityDate = from;
        filterObj.endOfAvailabilityDate = to;
      } else if (filterKey === 'hideUnkAvail') {
        filterObj.hideUnknownAvailabilityDate = filterValue;
      } else if (filterKey === 'furnished') {
        filterObj.furnished = filterValue;
      } else if (filterKey === 'isListedByOwner') {
        filterObj.isListedByOwner = filterValue;
      } else if (filterKey === 'acceptsSection8') {
        filterObj.acceptsSection8 = filterValue;
      } else if (filterKey === 'keywords') {
        filterObj.keywords = decodeURIComponent(filterValue);

        // HPWEB-5474: Search by Commute A/B test
      } else if (filterKey === 'commuteMode') {
        filterObj.commuteMode = filterValue;
      } else if (filterKey === 'commuteTime') {
        filterObj.commuteTime = filterValue;
      } else if (filterKey === 'commuteLats') {
        filterObj.commuteLats = filterValue;
      } else if (filterKey === 'commuteLons') {
        filterObj.commuteLons = filterValue;
      } else if (filterKey === 'commuteTimeMode') {
        filterObj.commuteTimeMode = filterValue;
      }
    });

    return filterObj;
  },
  mapDataIsDifferent({ mapData, query }) {
    if (Number(mapData.zoom) !== Number(query.z)) {
      return true;
    } else if (Number(mapData.lat) !== Number(query.lat)) {
      return true;
    } else if (Number(mapData.lon) !== Number(query.lon)) {
      return true;
    } else if (mapData.border !== query.border) {
      return true;
    } else {
      return false;
    }
  },
  mergeMapDataIntoQuery({ mapData, query }) {
    const newQueryObj = assign({}, query);

    // HPWEB-2850: Map can unexpectedly revert to original zoom / location
    // if min/max lat/ on from query params are present in URL. After getting
    // new map data, delete them from newQueryObj as they aren't needed.
    delete newQueryObj.minLat;
    delete newQueryObj.minLon;
    delete newQueryObj.maxLat;
    delete newQueryObj.maxLon;

    // should use either MapData or MapDataQueryObj, or better consolidate those so we just have 1.....
    newQueryObj.z = Number(mapData.zoom);
    newQueryObj.lat = Number(mapData.lat).toFixed(4);
    newQueryObj.lon = Number(mapData.lon).toFixed(4);
    if (mapData.border === false) {
      newQueryObj.border = false;
    }
    return newQueryObj;
  },
  sanitizeFilter(filter) {
    return reduce(
      filter,
      (result, val, key) => {
        /* eslint-disable no-undefined */
        if (val !== undefined) {
          result[key] = val;
        }
        return result;
      },
      {},
    );
  },
  shouldSkipSearchSlugApi({ isClientSideLoadedPage, hasFilterBeenSet, hasPagination, queryParams, filterFromCache }) {
    /**
     * Calling the api is unnecessary when:
     * - the app is client-side rendered
     * - `loadFilterFromSearchSlugApi` was called in `loadFilterFromUrl`
     * - filter query exists (the filter is generated after hitting the api already) *OR*
     *   without filter query (searchSlug only) when the filter already exists in the cache
     */
    if (!isClientSideLoadedPage || hasFilterBeenSet || hasPagination) {
      return false;
    }

    // when shouldUseCache === true, queryParams always contains searchSlug
    // check if other filters params present
    if (Object.keys(queryParams).length > 1) {
      return true;
    }

    if (filterFromCache && !isEmpty(filterFromCache)) {
      return true;
    }

    return false;
  },
};

export default controller;
