import type { ClickstreamEvent } from 'app/types';
import { Envelope } from './Envelope';

export const TrackNextClickExplorePage = () => {
  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({
      eventId: '5984',
      eventVersion: '1',
      templateId: '171',
      templateVersion: '1',
    }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'search_results',
      trigger_source_nm: 'button_to_next_page_srp',
      trigger_object_nm: 'search_results|explore',
    },
    semantic: {
      semantic_event_nm: 'explore_next_page',
    },
  };

  return csPayload;
};

type TriggerLocation = 'user_item_viewed_page' | 'user_item_hidden_page' | 'user_item_list_page' | 'search_results';
type TriggerObject = 'user_item_page|header' | 'search_results|header';
export const TrackSortListings = ({
  triggerLocation,
  triggerObject,
}: {
  triggerLocation: TriggerLocation;
  triggerObject: TriggerObject;
}) => {
  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({
      eventId: '5989',
      eventVersion: '1',
      templateId: '171',
      templateVersion: '1',
    }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: triggerLocation,
      trigger_source_nm: 'sort_by_dropdown',
      trigger_object_nm: triggerObject,
    },
    semantic: {
      semantic_event_nm: 'sort_order_changed',
    },
  };

  return csPayload;
};

export type SuggestedAreaTriggerLocation = 'search_results' | 'hp_homepage';
export type SuggestedAreaTriggerObject = 'hp_home_page_component|search' | 'filter_panel';

export const TrackSuggestedArea = ({
  triggerLocation,
  triggerObject,
}: {
  triggerLocation: SuggestedAreaTriggerLocation;
  triggerObject: SuggestedAreaTriggerObject;
}) => {
  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({
      eventId: '5929',
      eventVersion: '1',
      templateId: '171',
      templateVersion: '1',
    }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: triggerLocation,
      trigger_source_nm: 'autocomplete_box',
      trigger_object_nm: triggerObject,
    },
    semantic: {
      semantic_event_nm: 'search_select_area_suggestion',
    },
  };

  return csPayload;
};
